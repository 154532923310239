















































import { Component, Vue } from 'vue-property-decorator';
import { appName } from '@/env';

@Component
export default class Login extends Vue {
  public email: string = '';
  public password: string = '';
  public appName = appName;

  public get loginError() {
    // return readLoginError(this.$store);
    return null;
  }

  public async submit() {
    await this.$store.dispatch('api/logIn', {
          username: this.email.trim(),
          password: this.password,
        }
    );

    if (this.$store.getters['api/isLoggedIn']) {
      await this.$router.push('/main')
    }
  }
}
